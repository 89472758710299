﻿.ideas-link {
    position: relative;
    text-transform: uppercase;
    color: $color_text;
    font-weight: 600;
    font-size: typeScale(2);
    transition: all $transition-duration;
    margin-top: $padding;

    > span {
        position: relative;
        z-index: 1;
    }

    &::before {
        content: '';
        position: absolute;
        top: -.25em;
        left: 0;
        z-index: 0;
        width: 1em;
        height: 2em;
        border-radius: .5em;
        background-color: $color_standout;
        transform: rotate(40deg) scale(1);
        transform-origin: center;
        transition: all $transition-duration;
    }

    &:hover,
    &:focus,
    &:active {
        color: $color_primary;

        &::before {
            transform: rotate(40deg) scale(1.25);
        }
    }
}
