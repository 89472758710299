.page-content {

    @media (max-width: $bp-small - 1) {
        padding: 30px 16px 16px 72px;
    }

    @media (min-width: $bp-small) and (max-width: $bp-medium - 1) {
        padding: 44px 44px 44px 104px;
    }

    @media (min-width: $bp-medium) and (max-width: $bp-large - 1) {
        padding: 44px 44px 44px 218px;
    }

    @media (min-width: $bp-large) {
        padding: 56px 56px 56px 242px;
    }
}