@import '../../transitions/idea';

.idea-cover {
    margin: 0;
    padding: 0;

    &.idea-enter {
        animation-name: idea-enter;
        animation-duration: $transition-duration;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }

    &.idea-leave {
        animation-name: idea-leave;
        animation-duration: $transition-duration;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }

    @media (orientation: portrait) {

        > .overlay {
            display: block;
        }
    }

    @media (orientation: landscape) {

        > .overlay {
            display: none;
        }
    }
}