// animazione entrata sfondo
@keyframes expand-enter {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

// animazione uscita sfondo
@keyframes expand-leave {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-300vh);
  }
}

@keyframes delay-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes delay-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.navigation-menu {

    > .logo {
        position: relative;
        z-index: 10;
        display: inline-block;
    }

    > .social {
        position: absolute;
        bottom: 12px;
        left: 12px;
        z-index: 10;
    }

    > .overlay {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 3;
        width: $padding * 4;
        height: 100vh;
        background-color: rgba(0,0,0,.75);
        backdrop-filter: blur(5px) saturate(50%);
        transition: all $transition-duration;
        transform: translateX(-100%);
        display: none !important;

        &.-visible {
            transform: translateX(0);
        }
    }

    > .content {

        .menutoggle {
            position: absolute;
            z-index: 10;
            top: 50%;
            left: 15px;
            transform: translateY(-18px)
        }

        > .menucontent {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 5;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: flex-start;
            width: 100vw;
            height: 100vh;
            overflow: hidden;
            opacity: 1;

            &.delay-out {

                &-enter {
                    opacity: 0;
                }

                &-enter-active {
                    animation-name: delay-in;
                    animation-duration: 10ms;
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;
                }

                &-leave {
                    opacity: 1;
                }

                &-leave-active {
                    animation-name: delay-out;
                    animation-duration: 10ms;
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;
                    animation-delay: $transition-duration * 2;
                }
            }

            > .background {
                position: absolute;
                top: 50%;
                z-index: 1;
                width: 500vh;
                height: 500vh;
                margin: -250vh 0 0 -250vh;
                border-radius: 50%;
                transform-origin: center;
                transform: scale(1);
                opacity: 1;
                background-color: $color_primary;

                &.expand {

                    &-enter {
                        animation-name: expand-enter;
                        animation-duration: $transition-duration * 2;
                        animation-fill-mode: forwards;
                        animation-iteration-count: 1;
                    }

                    &-leave {
                        border-radius: 0;
                        animation-name: expand-leave;
                        animation-duration: $transition-duration * 2;
                        animation-fill-mode: forwards;
                        animation-iteration-count: 1;
                    }
                }
            }

            > ul {
                list-style: none;
                z-index: 2;
                position: relative;
                padding: 0;
                margin: 0;

                > li {

                    > a {
                        color: inherit;
                    }

                    &.primary {

                        > a {
                            font-family: $title-font;
                            text-decoration: none;
                            line-height: 1.5;
                            white-space: nowrap;
                            font-style: italic;

                            &:hover {

                                > strong {
                                    transform: scale(1.05)
                                }
                            }

                            > strong {
                                display: inline-block;
                                font-weight: 700;
                                transition: all $transition-duration;

                                @include fade-zoom($transition-duration, 10ms, 'smart');
                                @include fade-zoom($transition-duration, 100ms, 'business');
                                @include fade-zoom($transition-duration, 200ms, 'ideas');
                            }

                            > span {
                                font-weight: 300;
                                font-size: 50%;
                                margin-left: .5em;
                                color: #d2b5c9;

                                @include fade-blur($transition-duration * 1.5, 10ms, 'smart');
                                @include fade-blur($transition-duration * 1.5, 100ms, 'business');
                                @include fade-blur($transition-duration * 1.5, 200ms, 'ideas');
                            }
                        }
                    }

                    &.secondary {
                        line-height: 1.25;

                        > a {
                            display: inline-block;
                            white-space: nowrap;
                            font-family: $text-font;
                            font-weight: 300;
                            text-decoration: none;
                            transition: all $transition-duration;
                            font-size: 66.667%;
                            color: #d2b5c9;

                            &:hover,
                            &:focus,
                            &:active {
                                transform: scale(1.05)
                            }

                            @include fade-blur($transition-duration, 100ms, 'contact');
                        }
                    }
                }
            }
        }
    }

    @media(max-width: $bp-small - 1) {

        > .content > .menucontent {
            padding: $padding * 4 $padding $padding * 5 $padding * 5;
            font-size: 36px;

            > .background {
                left: $padding * 2;
            }
        }

        > .social {
            transition: all $transition-duration;
        }

        &:not(.-open) {

            > .social {
                opacity: 0;
                visibility: hidden;
            }
        }

        &.-open {

            > .social {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    @media(min-width: $bp-small) {

        > .content > .menucontent {
            padding: $padding * 5.5 $padding * 1.5 $padding * 6.5 $padding * 6.5;
            font-size: 6vw;

            > .background {
                left: $padding * 2.75;
            }
        }
    }

    @media (min-width: $bp-medium) {

        > .content > .menucontent {
            font-size: 48px;
            padding: $padding * 7 $padding * 3 $padding * 8 $padding * 13;
        }
    }

    @media (min-width: $bp-large) {

        > .content > .menucontent {

            > .background {
                left: $padding * 3.5;
            }
        }
    }
}
