.menu-toggle{
    appearance: none;
    -webkit-appearance: none;
    border: 0;
    display: inline-block;
    width: 36px;
    height: 36px;
    padding: 0;
    cursor: pointer;
    background-color: transparent;
    outline: none;

    &:hover,
    &:focus,
    &:active {
        background-color: transparent;
    }

    > i {
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      width: 100%;
      height: 2px;
      transition: background-color 400ms;
      border-color: inherit;

      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 0;
        border-top-width: 1px;
        border-top-style: solid;
        border-color: inherit;
        transition: transform $transition-duration, border-color $transition-duration;
      }

      &::before {
        transform: translateY(0)
      }

      &::after {
        transform: translateY(6px)

      }
    }

    &.-open {

      > i {

        &::before,
        &::after {
          border-bottom-width: 1px;
          border-bottom-style: solid;
        }

        &::before {
          transform: translateY(0) rotate(-45deg);
        }

        &::after {
          transform: translateY(0) rotate(45deg);
        }
      }
    }
  }
