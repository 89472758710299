.contact-page {
    @extend .page-content;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;

    > aside {
        flex: 1 1 100%;
        font-size: typeScale(3);

        > p {
            max-width: 960px;
            margin: 0 auto;

            > a {
                color: $color_standout;
                text-decoration: underline;
            }
        }
    }

    > div {
        flex: 1 1 320px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: flex-start;

        > h3 {
            font-family: $font-primary;
            font-size: typeScale(2);
            opacity: .35;
            font-weight: 400;
        }

        > p {

            > a {
                color: $color_standout;
                text-decoration: underline;

                &[href^="tel"] {
                    color: $color_text;
                    text-decoration: none;
                }
            }
        }

        > nav {

            > ul > li {
                margin-bottom: 1em;

                > a {
                    color: $color_text;

                    &:hover,
                    &:focus,
                    &:active {
                        color: $color_standout;
                    }
                }
            }
        }
    }

    > footer {
        flex: 1 1 100%;
        align-self: flex-end;
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: flex-end;

        > small {
            flex: 1 1 auto;
            opacity: .35;
        }

        > a {
            display: inline-block;
            padding: 1em 1em 0 0;
            flex: 0 0 auto;
            color: $color_text;
            user-select: none;
        }
    }

    @media (max-width: 900px) {

        > aside {
            display: none;
        }

        > .menu {
            display: none;
        }

        > div {

            > h3,
            > p {
                margin: .5em 0;
            }
        }
    }

    @media (min-width: 901px) {
        align-items: center;

        > aside {
            margin-bottom: 2rem;
        }
    } 

    @media (min-width: 1250px) {
        justify-content: center;


        > div {
            flex: 0 1 320px;
            align-self: flex-start;
        }

        > footer {
            justify-content: center;

            > small {
                flex: 0 0 720px;
            }

            > a {
                flex: 0 0 120px;
            }
        }
    }
}
