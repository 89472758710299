@mixin title($larger: false, $ratioVw: 3.5) {
    font-family: $title-font;
    line-height: 1.1;
    margin: 0;

    @media (max-width: $bp-small - 1) {
      font-size: $bp-small * $ratioVw / 100;
    }

    @media (min-width: $bp-small) {
      font-size: #{$ratioVw}vw;
    }

    @if $larger {

      @media (min-width: $bp-larger) {
        font-size: $bp-larger * $ratioVw / 100;
      }
    }
    @else {

      @media (min-width: $bp-large) {
        font-size: $bp-large * $ratioVw;
      }
    }
  }
