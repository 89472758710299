@mixin fade-zoom($duration: $transition-duration, $delay: 0, $suffix: '') {
    transform-origin: center;

    $class-name: 'fade-zoom';

    @if str-length($suffix) > 0 {
      $class-name: '#{$class-name}-#{$suffix}';
    }

    &.#{$class-name} {
      &-enter {
        opacity: 0;
      }

      &-enter-active {
        animation-name: fade-zoom;
        animation-duration: $duration;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
        animation-delay: $duration + $delay;
      }

      &-leave {
        animation-name: fade-zoom;
        animation-duration: $duration;
        animation-fill-mode: forwards;
        animation-direction: reverse;
        animation-iteration-count: 1;
      }
    }
  }

  @keyframes fade-zoom {
    0% {
      opacity: 0;
      transform: scale(1.1);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
