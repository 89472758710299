$bar_bg: rgb(51,51,51);
$bar_primary: rgb(26,213,108);
$bar_text: rgba(255,255,255,0.75);
$bar_link: rgb(255,255,255);

$modal_overlay: rgba(0,0,0,0.4);
$modal_bg: rgb(255,255,255);
$modal_footer: rgb(244,245,247);
$modal_title: rgba(0,0,0,0.8);
$modal_text: rgba(0,0,0,0.5);
$modal_icon: rgb(189,189,189);
$modal_primary: rgb(26,213,108);

$icon_checkmark: 'data:image/svg+xml;utf8,<svg height="11" viewBox="0 0 14 11" width="14" xmlns="http://www.w3.org/2000/svg"><path d="m5 10.42-5-5 1.41-1.41 3.59 3.58 7.59-7.59 1.41 1.42z" fill="rgb(255,255,255)" fill-rule="evenodd"/></svg>';
$icon_caret: 'data:image/svg+xml;utf8,<svg height="12" viewBox="0 0 8 12" width="8" xmlns="http://www.w3.org/2000/svg"><path d="m1.41 0-1.41 1.41 4.58 4.59-4.58 4.59 1.41 1.41 6-6z" fill="'+$bar_primary+'"/></svg>';

.gdpr-cookie-notice {
    background: getColor(text, primary);

    @media print {
        display: none;
    }

    &-description {
        color: getColor(text, inverted);
    }

    &-nav {

        &-item {
            color: getColor(text, inverted);

            &-btn {
                background: getColor(base, primary);
                color: getColor(text, primary);
                border-radius: 24px;
            }
        }
    }

    &-modal {
        background-color: rgba(0,0,0,.95);
        backdrop-filter: blur(5px);

        &-content {
            border-radius: 0;
            background: getColor(text, primary);
        }

        &-header {
            border-bottom: 1px solid getColor(background, light);
        }

        &-title {
            font-family: $font-primary;
            color: getColor(text, inverted);
        }

        &-close {

            &:hover,
            &:focus,
            &:active {
                background-color: transparent;
            }
        }

        &-cookie {
            border-bottom: 1px solid getColor(background, light);

            &-title {
                font-family: $font-primary;
                color: getColor(text, inverted);

                &:after {
                    border-color: getColor(background, light) transparent transparent transparent;
                }
            }

            &-state {
                color: getColor(text, heading);
            }

            &-input {

                &:checked + &-switch {
                    background: getColor(state, success);
                    box-shadow: inset 0px 0px 0px 1px getColor(state, success);
                }

                &-switch {
                    box-shadow: inset 0px 0px 0px 1px getColor(background, light);
                }
            }

            &-info {
                color: getColor(text, inverted);
            }
        }

        &-footer {
            background: getColor(background, light);
            border-radius: 0 0 15px 15px;

            &-item {
                color: getColor(base, primary);

                &:after {
                    background: none;
                }

                &-btn {
                    color: getColor(text, primary);
                    background: getColor(base, primary);
                    border-radius: 24px;
                }
            }
        }
    }
}
