﻿.large-claim {
    @include title(true);

    > span {
        font-weight: 400;
        font-style: italic;
    }

    > strong,
    > a {
        font-family: $title-font;
        font-weight: 700;
        font-style: normal;
        font-size: inherit;
        text-decoration: none;
        color: inherit;

        &:hover,
        &:focus,
        &:active {
            color: $color_text;
        }
    }
}