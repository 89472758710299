.home-idea {
    scroll-snap-align: start;
    scroll-snap-stop: always;

    @extend .page-content;
    position: relative;
    width: 100%;
    height: 100%;

    > h1 {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        height: 100%;
        margin: 0;
        text-shadow: 0 0 10px rgba(0,0,0,.85);
        user-select: none;
    }

    > svg {
        position: fixed;
        top: 5%;
        right: 5%;
        z-index: 0;
    }

    @media (orientation: portrait) {

        > svg {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    @media (orientation: landscape) {

        > svg {
            top: 5%;
            // bottom: 5%;
            right: 5%;
            width: 90vh;
            height: 90%;
            max-width: 960px;
            max-height: 960px;
        }
    }
}
