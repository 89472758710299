.scroll-hint {
    width: 9px;
    height: 100px;

    > path {
        fill: $color_standout;
        animation: hint 2000ms infinite ease-in-out;
    }

    > rect {
        fill: $color_text;
    }


    &.show-hint {

        &-enter{
            animation-name: show-hint;
            animation-duration: $transition-duration * 2;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
        }

        &-leave {
            animation-name: show-hint;
            animation-duration: $transition-duration * 2;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
            animation-direction: reverse;
        }
    }

    @media print {
        display: none !important;
    }
}

  @keyframes hint {
    0% {
      transform: translateY(0)
    }

    50% {
      transform: translateY(50px)
    }

    100% {
      transform: translateY(0)
    }
  }

  @keyframes show-hint {

    0% {
        transform: translateY(200px)
    }

    100% {
        transform: translateY(0);
    }
  }
