.idea-title {
    @include title(true);
    margin: 0;

    > a,
    > strong {
        display: block;
        color: inherit;

        &:hover,
        &:focus,
        &:active {
            color: inherit;
        }

        > p {
            display: contents;
        }
    }

    > a {
        transition: transform $transition-duration;
        transform-origin: center left;
        transform: scale(1);

        &:hover,
        &:focus,
        &:active {
            color: inherit;
            transform: scale(1.05)
        }
    }

    > span {
        font-size: typeScale(3);
        font-family: $font-primary;
        display: inline-block;
        padding-right: 1em;
        font-weight: 400;
    }
}
