﻿.page-about {
    $text-max-width: 42rem;

    @extend .page-content;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    min-height: 100%;

    > h1 {
        margin-bottom: 1em;
    }

    > h1,
    > h2,
    > h3,
    > h4,
    > h5,
    > h6,
    > ul,
    > ol,
    > p {
        position: relative;
        z-index: 3;
    }

    > h1 {
        max-width: 56rem;
    }

    > p {
        width: 100%;
        max-width: $text-max-width;
        font-size: typeScale(3);
        color: darken($color_text, 10%);

        > strong {
            color: $color_text
        }
    }

    > aside {
        width: 100%;
        max-width: $text-max-width;
        text-align: center;

        > .link {
            display: inline-block;
            margin: $padding * 2 auto 0 auto;
        }
    }

    > h3 {
        max-width: $text-max-width;
        font-size: typeScale(3);
        font-family: $font-primary;
    }

    > .business {
        list-style: none;
        margin: $padding 0;
        padding: 0;
        text-transform: uppercase;
        font-size: typeScale(3);
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        width: 100%;
        max-width: $text-max-width * 1.15;

        > li {
            padding: 0;
            flex: 1 1 $text-max-width * 0.575;
            clear: both;

            &:first-child,
            + li {
                margin-top: $padding * 1.5;
            }

            > svg {
                display: inline-block;
                vertical-align: middle;
                width: 3rem;
                height: 3rem;
                margin-right: 1rem;
                opacity: .9;
                float: left;

                > path,
                > polygon,
                > circle,
                > rect,
                > line,
                > polyline {
                    fill: none;
                    stroke: #f37920;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-width: 1.25px;
                }
            }

            > span {
                display: inline;
                vertical-align: middle;
                line-height: 1;
            }
        }
    }

    + .animation {
        position: fixed;
        top: 0;
        right: 0;
        z-index: 0;
        opacity: .25;
        filter: saturate(0) contrast(0) brightness(0.75);
    }

    @media (orientation: portrait) {

        + .animation {
            width: 100%;
            height: 100vw;
            padding: 5vw;
            top: calc((100% - 100vw) / 2 )
        }
    }

    @media (orientation: landscape) {

        + .animation {
            width: 100vh;
            height: 100vh;
            padding: 10vh;
        }
    }

    @media (min-width: 90rem) {

        + .animation {
            filter: unset;
            opacity: .95;
        }
    }
}
