.text-page {
    @extend .page-content;

    max-width: 58rem;

    > h1:first-child,
    > h2:first-child,
    > h3:first-child,
    > h4:first-child,
    > h5:first-child,
    > h6:first-child {
        margin-top: 0;
    }

    > h3,
    > h4,
    > h5,
    > h6 {
        font-family: $text_font;
    }

    > p {
        text-align: justify;
        text-align-last: left;
    }

    a {
        color: $color_text;
        text-decoration: underline;

        &:hover,
        &:focus,
        &:active {
            color: $color_standout;
        }
    }

    table {

        > thead {
            background-color: transparent;
            border: 1px solid $color_text;
        }
    }

    @media (min-width: $bp-large) {
        margin: 0 auto;
        padding: 56px;
    }
}