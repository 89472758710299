@mixin fade-blur($duration: $transition-duration * 1.5, $delay: 0ms, $suffix: '') {
    filter: blur(0);

    $class-name: 'fade-blur';

    @if str-length($suffix) > 0 {
      $class-name: '#{$class-name}-#{$suffix}';
    }

    &.#{$class-name} {

      &-enter {
        opacity: 0;
      }

      &-enter-active {
        animation-name: fade-blur;
        animation-duration: $duration;
        animation-delay: $duration + $delay;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
      }

      &-leave {
        opacity: 1;
      }

      &-leave-active {
        animation-name: fade-blur;
        animation-duration: $duration;
        animation-delay: 10ms;
        animation-direction: reverse;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
      }
    }
  }


  @keyframes fade-blur {
    0% {
      opacity: 0;
      filter: blur(7px);
    }

    100% {
      opacity: 1;
      filter: blur(0);
    }
  }
