.idea-top {
    @extend .page-content;
    padding-right: 0;

    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
    min-height: 100%;
    user-select: none;

    > h1 {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        width: 100%;
        max-width: 1540px;
        margin: 0 0 1em 0;
    }

    > img {
        width: 100%;
        height: 65vh;
        max-width: 1540px;
        max-height: 780px;
        object-fit: cover;
    }

    > .abstract {
        width: 100%;
        max-width: 1540px;
        margin: $padding * 2 0 0;


        > div {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
            max-width: 1024px;

            &:after {
                content: '';
                flex: 1 1 20%;
                min-width: 200px;
            }

            > div {
                flex: 1 1 20%;
                min-width: 200px;

                > h4 {
                    font-family: $font-primary;
                    text-transform: uppercase;
                    font-size: typeScale(2);
                    margin: 0 0 .25em 0;
                }

                > p {
                    margin: 0;
                }
            }
        }
    }

    + .idea-content {
        padding-top: 0 !important;
    }

    @media (orientation: landscape) {

        > h1,
        > img
        > .abstract {
            max-width: 80vw;
        }

        > img {
            max-height: 65vh;
        }
    }

    @media (max-width: $bp-small - 1) {

        > .abstract {
            > div {

                > div {
                    padding: $padding * .5;
                    padding-left: 0;
                }
            }
        }
    }

    @media (min-width: $bp-small) and (max-width: $bp-large - 1) {

        > .abstract {

            > div {

                > div {
                    padding: $padding;
                    padding-left: 0;
                }
            }
        }
    }

    @media (min-width: $bp-large) {

        > .abstract {

            > div {

                > div {
                    padding: $padding * 2;
                    padding-left: 0;
                }
            }
        }
    }
}
