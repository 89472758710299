.social-icons {
    list-style: none;
    margin: 0;
    padding: 0;

    > li {
      position: relative;
      padding: 0;
      text-align: center;

      > a {
        display: inline-block;
        height: 100%;
        width: 20px;
        height: 20px;
        margin: 10px;

        > svg {

          > path {
            transition: fill $transition-duration;
          }
        }
      }
    }
  }