.scroll-context {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    overflow: hidden;
    font-family: $text-font;
    background: radial-gradient(rgba(0,0,0,.05) 60%, rgba(0,0,0,.65) 95%), url('/commons/img/static-noise-dark.gif');

    > .hint {
        position: absolute;
        bottom: -20px;
        left: 50%;
        z-index: 40;
        margin-left: -9px;
        transform: translateY(0);
    }

    > .menu {
        position: fixed;
        z-index: 50;
        width: $padding*4;
        height: 100%;
        overflow: visible;
        background-color: transparent;
        transition: top $transition-duration, bottom $transition-duration, left $transition-duration, transform $transition-duration;
        user-select: none;
        transform: translateX(0);

        &.-hidden {
            transform: translateX(-200px);
        }


        > .content {

            > .menutoggle {
                width: 36px;
                height: 36px;
                border-color: $color_text;

                &.-dark {
                    border-color: $color_primary;
                }
            }
        }

        > .social > li > a.-dark > svg > * {
            fill: $color_primary;
        }

        &.-open {

            > .logo > svg.-dark > path[fill="#f8f7f9"] {
                fill: $color_text;
            }

            > .content > .menutoggle.-dark {
                border-color: $color_text;
            }

            > .social > li > a.-dark > svg > * {
                fill: $color_text;
            }
        }

        > .overlay {
            width: $padding * 4;
        }
    }

    > .content {
        position: relative;
        z-index: 5;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: scroll;
        scroll-behavior: smooth;

        &::-webkit-scrollbar {
            display: none;
        }

        &.-snap-proximity {
            scroll-snap-type: y proximity;
        }

        &.-snap-mandatory {
            scroll-snap-type: y mandatory;
        }
    }

    > .background {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1;
    }

    @media (max-width: $bp-small - 1) {

        > .menu {
            height: calc(100% - #{$padding * 0} );
            top: $padding*0;
            bottom: $padding*0;
            left: $padding*0;
        }
    }

    @media (min-width: $bp-small) {

        > .menu {
            height: calc(100% - #{$padding * 1.5} );
            top: $padding*.75;
            bottom: $padding*.75;
            left: $padding*.75;

            > .content {

                > .menucontent {
                    top: $padding * -.75;
                    bottom: $padding * -.75;
                    left: $padding * -.75;
                }
            }
        }
    }

    @media (min-width: $bp-large) {

        > .menu {
            height: calc(100% - #{$padding * 3} );
            top: $padding*1.5;
            bottom: $padding*1.5;
            left: $padding*1.5;

            > .content {

                > .menucontent {
                    top: $padding * -1.5;
                    bottom: $padding * -1.5;
                    left: $padding * -1.5;
                }
            }
        }
    }

    @media print {
        position: relative;
        background: none;
        background-color: #fff !important;
        overflow: visible;

        > .content {
            overflow: visible !important;
        }
    }
}
