.idea-content {
    @extend .page-content;
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;

    + .home-idea {
        position: relative;
        z-index: 1
    }

    + .idea-content {
        margin-top: -1px;
    }

    > div {
        width: 100%;
        max-width: 1540px;
        padding-right: $padding;

        > * {
            max-width: 1024px;
            margin: 0;

            + * {
                margin-top: 1em;
            }
        }
    }

    p,
    ol,
    ul,
    table {
        font-size: typeScale(3);
    }

    h3,
    h4,
    h5,
    h6 {
        font-family: $font-primary;
    }

    h1,
    h2 {
        font-size: typeScale(6);
    }

    h3 {
        font-size: typeScale(4);
    }

    h4,
    h5,
    h6 {
        font-family: $font-primary;
        font-size: typeScale(3);
    }

    img {
        width: 100%;
        max-width: 1024px;
        height: auto;
        margin: 0;
    }

    &.-bg-white {
        background-color: getColor(background, light);
        color: $color_background;

        p,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        ul,
        ol,
        table {
            color: $color_background
        }

        + .-image {
            margin-top: 0;

            img {
                margin-top: 0;
            }
        }
    }

    &.-image {
        margin-top: 30vw;

        img {
            margin-top: -30vw;

            &::selection {
                background-color: transparent;
            }
        }
    }

    &.-video {
        width: 100%;
        padding: 0 !important;
        background-color: #000;
        scroll-snap-align: start;
        scroll-snap-stop: always;

        > div {
            width: 100%;
            height: 56.35vw;
            max-height: 100vh;
            max-width: 100%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;

            > * {
                max-width: none;
            }

            [id*="MediaController-DurationLabel"] {
                display: none !important;
            }
        }
    }


    @media (max-width: $bp-small - 1) {

        &:not(.-image):not(.-video) {
            padding-top: 30px;
            padding-bottom: 16px;
        }
    }

    @media (min-width: $bp-small) and (max-width: $bp-medium - 1) {

        &:not(.-image):not(.-video) {
            padding-top: 44px;
            padding-bottom: 44px;
        }
    }

    @media (min-width: $bp-medium) and (max-width: $bp-large - 1) {

        &:not(.-image):not(.-video) {
            padding-top: 44px;
            padding-bottom: 44px;
        }
    }

    @media (min-width: $bp-large) {
        &:not(.-image):not(.-video) {
            padding-top: 56px;
            padding-bottom: 56px;
        }
    }

    @media (min-width: 1280px) {

        &.-image {
            padding-top: 0;
            padding-bottom: 0;
            margin-top: 340px;

            img {
                margin-top: -340px
            }
        }
    }
}
