@keyframes slide-off {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.animated-slides {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    scroll-snap-align: start;
    scroll-snap-stop: always;

    &.slide-off-leave {
        animation-name: slide-off;
        animation-duration: $transition-duration;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
    }

    &.slide-off-enter {
        animation-name: slide-off;
        animation-duration: $transition-duration;
        animation-iteration-count: 1;
        animation-fill-mode: backwards;
    }

    > .slide {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: flex-start;
        width: 100vw;
        height: 100%;
        margin: 0;

        @extend .page-content;

        > h1 {
            position: relative;
            margin: 0;
            z-index: 5;
            text-shadow: 0 0 10px #000;

            > span {
                font-weight: 400;
                font-style: italic;

                @include fade-blur();
            }

            > a {
                position: relative;
                display: inline-block;
                font-family: $title-font;
                font-weight: 700;
                font-style: normal;
                font-size: inherit;
                text-decoration: none;
                color: inherit;
                opacity: 1;
                transform: scale(1);
                @include fade-zoom();

                &:hover,
                &:focus,
                &:active {
                    color: $color_text;
                }

                > .ticker {
                    display: block;
                    position: absolute;
                    top: 82%;
                    left: 0;
                    z-index: 1;
                    height: .1em;
                    margin: 0;
                    background-color: $color_standout;
                }
            }
        }

        > .animation {
            position: absolute;
            z-index: 0;
            margin: 0;
            overflow: visible;
            opacity: .95;

            @extend .page-content;

            > svg {
                overflow: visible;
            }
        }
    }

    @media (orientation: landscape) {

        > .slide {

            > .animation {
                top: 0;
                right: 0;
                margin-right: -2.5vw;
                width: 100vh;
                height: 100vh;
                max-width: 60vw;
                max-height: 100vh;
                padding: 10vh;
            }
        }
    }

    @media (orientation: landscape) and (min-width: $bp-larger) {

        > .slide {

            > .animation {
                right: auto;
                margin-right: 0;
                left: 50vw;
                padding-left: 0;
            }
        }
    }

    @media (orientation: portrait) {

        > .slide {
            justify-content: flex-start;

            > h1 {
                @include title(false, 4.5);
                margin-top: 2em;
            }

            > .animation {
                top: calc(((100% - 100vw) / 2) + 7vh);
                bottom: calc(((100% - 100vw) / 2) - 7vh);
                left: 0;
                right: 0;
                width: 100vw;
                height: 100vw;
                max-width: 100vw;
                max-height: 100vw;
                margin: 0;
                padding: 0;
            }
        }
    }

    @media (min-width: 900px) and (max-width: 1650px) {

        > .slide {

            > h1 {
                font-size: 2.5vw;
                text-shadow: 0 0 5px #000;
            }
        }
    }
}