.home-credits {
    @extend .page-content;
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
    height: 100%;
    background-color: getColor(background, light);
    scroll-snap-align: start;
    scroll-snap-stop: always;

    + section {
        scroll-snap-align: start;
        scroll-snap-stop: always;
    }

    > h2 {
        flex: 1 1 100%;
        margin: 0;
        text-align: center;
        color: $color_background;
        font-size: typeScale(4);
        line-height: 1.1;

        > strong {
            font-weight: 700;
        }

        > span {
            font-weight: 400;
            font-style: italic;
        }
    }

    > img {
        object-fit: contain;
        max-width: 250px;
    }

    > .link {
        color: $color_background;
        margin-top: $padding;
    }

    @media (orientation: landscape) {

        > img {
            flex: 0 1 20%;
            max-width: 20%;
            padding: 1em;
        }
    }

    @media (orientation: portrait) {

        > h2 {
            max-height: 30vh;
        }

        > img {
            flex: 0 1 32%;
            max-width: 32%;
            max-height: 12vh;
            margin: 0;
            padding: 10px;
            margin: .5%;
            object-fit: contain;
        }
    }

    @media (max-width: $bp-small - 1) {
        padding-right: 72px;
    }

    @media (min-width: $bp-small) and (max-width: $bp-medium - 1) {
        padding-right: 104px;
    }

    @media (min-width: $bp-medium) and (max-width: $bp-large - 1) {
        padding-right: 218px;
    }

    @media (min-width: $bp-large) {
        padding-right: 242px;

        > h2 {
            font-size: typeScale(5);
        }

        > img {
            padding: $padding * 2;
        }
    }

    @media (min-width: $bp-larger) {

        > h2 {
            font-size: typeScale(6);
        }

        > img {
            padding: $padding * 3;
        }
    }
}
