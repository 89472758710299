@keyframes fade-e {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(50px);
  }
}

@keyframes fade-t {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-50px);
  }
}

@keyframes fade-r {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-100px);
  }
}


@keyframes fade-a {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-100px);
  }
}


.animated-logo {
    transition: all $transition-duration;
    overflow: visible;
    transform: translateX(0);

    &.-compressed {
      transform: translateX(-33px);
    }

    &.-dark {

      > path[fill="#f8f7f9"] {
        fill: $color_primary;
      }
    }

    >  path {
      position: relative;
      transform-origin: center;
      transition: all $transition-duration;


      &.fade-e {
        &-enter{
          animation-name: fade-e;
          animation-duration: $transition-duration;
          animation-fill-mode: forwards;
          animation-direction: reverse;
          animation-iteration-count: 1;
        }

        &-leave {
          animation-name: fade-e;
          animation-duration: $transition-duration;
          animation-fill-mode: forwards;
          animation-iteration-count: 1;
        }
      }

      &.fade-t {
        &-enter{
          animation-name: fade-t;
          animation-duration: $transition-duration;
          animation-fill-mode: forwards;
          animation-direction: reverse;
          animation-iteration-count: 1;
        }

        &-leave {
          animation-name: fade-t;
          animation-duration: $transition-duration;
          animation-fill-mode: forwards;
          animation-iteration-count: 1;
        }
      }

      &.fade-r {
        &-enter{
          animation-name: fade-r;
          animation-duration: $transition-duration;
          animation-fill-mode: forwards;
          animation-direction: reverse;
          animation-iteration-count: 1;
        }

        &-leave {
          animation-name: fade-r;
          animation-duration: $transition-duration;
          animation-fill-mode: forwards;
          animation-iteration-count: 1;
        }
      }

      &.fade-a {
        &-enter{
          animation-name: fade-a;
          animation-duration: $transition-duration;
          animation-fill-mode: forwards;
          animation-direction: reverse;
          animation-iteration-count: 1;
        }

        &-leave {
          animation-name: fade-a;
          animation-duration: $transition-duration;
          animation-fill-mode: forwards;
          animation-iteration-count: 1;
        }
      }

      &.fade {
        &-enter-active ,
        &-leave-active {
          transition: opacity 500ms;
        }
        &-enter,
        &-leave-to {
          opacity: 0;
        }
      }
    }

    @media (max-width: $bp-small - 1) {
      transform: scale(.75) translateX(-15px);

      &.-compressed {
        transform: scale(.75) translateX(-48px)
      }
    }

    @media print {

      > path {
        fill: #1b1b1b !important;
      }
    }
}